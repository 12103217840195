import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index')
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login/login.vue')
  // },
  {
    path: '/paypc',
    name: 'paypc',
    component: () => import('@/views/paypc/paypc')
  }, {
    path: '/payphone',
    name: 'payphone',
    component: () => import('@/views/payphone/payphone')
  }
  ,
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
