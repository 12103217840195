import Vue from 'vue'
// 按需引入
import {
  Button, MessageBox, Message, Dropdown, DropdownMenu, DropdownItem, Icon, Avatar
} from 'element-ui'
export default {
  components: [
    Button,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Icon,
    Avatar
  ]
}
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$prompt = MessageBox.prompt